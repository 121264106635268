@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.article {
  margin-bottom: 16px;
  @include transition($common-transition);
  overflow: hidden;
  position: relative;

  .postimg {
    overflow: hidden;
    position: relative;
    border-radius: 8px;

    img {
      @include transition($common-transition);
    }

    &:hover img {
      @include transform(scale(1.1));
    }
  }

  img {
    object-fit: cover;
  }

  .badge {
    margin-bottom: 8px;
  }

  .articleInfo {
    font-size: 11px;
    line-height: 14px;
    color: #838A96;

    >span {
      &:not(:last-child)::after {
        margin: 0px 8px;
        // margin: 0px 4px;
        @extend %after-before;
        position: relative;
        background: #838A96;
        width: 1px;
        height: 50%;
        // border-radius: 50%;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .icon {
      margin-right: 2px;
      width: 14px;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  h3,
  h4 {
    a {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .desc {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #838A96;
  }

  &.fullCard {
    border-radius: 8px;

    .postDesc {
      color: #000000;
      z-index: 2;

      [data-light-mode="false"] & {
        color: var(--white);
      }

      .articleInfo {
        >span {
          &:not(:last-child)::after {
            background: var(--white);
          }
        }
      }
    }

    .articleInfo {
      color: var(--white);
    }

    &::after {
      @extend %after-before;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      z-index: 1;
    }
  }
}

.videoIcon {
  top: 12px;
  left: 12px;
  width: 24px;
  height: 24px;
  z-index: 5;
}

.videoTime {
  padding: 2px 10px;
  position: absolute;
  top: 154px;
  right: 10px;
  background: #000000;
  color: var(--light-mode-bg);
  border-radius: 2em;
  background-color: #000000;
  align-items: center;
  gap: 5px;

  [data-light-mode="false"] & {
    color: var(--white);
  }
}

.videoTimeBig {
  padding: 2px 10px;
  position: absolute;
  top: 73%;
  right: 10px;
  background: #000000;
  color: var(--light-mode-bg);
  border-radius: 2em;
  background-color: #000000;
  align-items: center;
  gap: 10px;
}

/***** Responsive CSS Start ******/
// @media (max-width: 1199px) {
  @media (min-width: 1200px) and (max-width: 1399px) {
    .videoTime {
      top: 42%;
    }
  }
  @media (min-width: 1199px) and (max-width:1400px ) {
    .videoTime {
      top: 122px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .article {
      .articleInfo {
        >span {
          margin-right: 6px;
        }
      }
    }

    .videoTime {
      top: 83px;
    }
  }

  @media (max-width: 767px) {
    .videoTime {
      top: 72%;
    }
  }

  @media (max-width: 575px) {
    .article {
      .postimg {
        border-radius: 6px;
      }
    }

    .videoTime {
      top: 57%;
    }
  }
// }